const emailRegex = new RegExp(/(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/);
const passwordRegex = new RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$ %^&*-]).{8,16}$/);
const urlRegex = new RegExp(/https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()!@:%_+.~#?&/=]*)/g);
const atRegex = new RegExp(/@\S+?(?:#[0-9]+)?[ ]+/g)

const unique = (arr: string[]) => {
  for (let i = 0; i < arr.length; i++) {
    for (let j = i + 1; j < arr.length; j++) {
      if (!arr[i].localeCompare(arr[j])) {
        arr.splice(j, 1)
        j--
      }
    }
  }
  return arr
}

const snakeCaseToCamel = (str: string) => str.toLowerCase().replace(/[-_][a-z]/g, (group) => group.slice(-1).toUpperCase());

const camelToSnakeCase = (str: string) => str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);

const getHighlightedText = (text: string, highlight: string, isTags = false) => {
  if (isTags) {
    let tagList = text.split(",")
    const index = tagList.map(tag => tag.toLowerCase()).indexOf(highlight.toLowerCase())
    if (index !== -1 && index !== 0) {
      tagList = tagList.filter(tag => tag.toLowerCase() !== highlight.toLowerCase())
      tagList.unshift(highlight)
    }
    text = tagList.join(", ")
  }
  const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
  return parts.filter(part => part.length > 0).map((part, i) => <span key={i} className={`${part.toLowerCase() === highlight.toLowerCase() ? "bg-yellow-100 p-0.5 whitespace-nowrap" : "whitespace-nowrap" }`}>{ part }</span>);
}

const renderLineBreak = (content:string) => {
  return content.split("\n").filter(p => p).map(p => "<p>" + p + "</p>").join("")
}

const renderPreview = (content: string) => {
  const urlArr = content.match(urlRegex)
  if (urlArr && urlArr.length > 0) {
    urlArr.forEach(el => {
      if (el.endsWith(".jpg") || el.endsWith(".png") || el.endsWith(".gif") || el.endsWith("webp")) {
        content = content.replace(el, "<img src='" + el + "' alt='' />")
      } else {
        content = content.replace(el, "<a href='" + el + "' rel='noreferrer nofollow'>" + el + "</a>")
      }
    })
  }

  if (/^@\S+?#[0-9]+/.test(content)) {
    content = content.replace(/^(@\S+?#[0-9]+).*/, "$1") + content.replace(/@\S+?#[0-9]+/g, "")
  } else {
    content = content.replace(/@\S+?#[0-9]+/g, "")
  }

  const atArr = content.match(atRegex)
  if (atArr && atArr.length > 0) {
    atArr.forEach(el => {
      if (el.includes("#")) {
        const temp = el.trim().split("#")
        content = content.replace(el, " <a class='comment-at' href='#comment-" + temp[1] + "' rel='noreferrer nofollow'>" + temp[0] + "</a> ")
      } else {
        content = content.replace(el, " <a class='comment-at'>" + el.trim() + "</a> ")
      }
    })
  }
  return renderLineBreak(content)
}

const setEndOfContenteditable = (contentEditableElement: HTMLDivElement) => {
  contentEditableElement.focus()
  let range, selection;
  if(document.createRange) { //Firefox, Chrome, Opera, Safari, IE 9+
    range = document.createRange(); //Create a range (a range is a like the selection but invisible)
    if (range) {
      range.selectNodeContents(contentEditableElement); //Select the entire contents of the element with the range
      range.collapse(false); //collapse the range to the end point. false means collapse to end rather than the start
      selection = window.getSelection(); //get the selection object (allows you to change selection)
      if (selection) {
        selection.removeAllRanges(); //remove any selections already made
        selection.addRange(range); //make the range you have just created the visible selection
      }
    }
  }
}

export {emailRegex, passwordRegex, urlRegex, atRegex, unique, snakeCaseToCamel, camelToSnakeCase, getHighlightedText, renderLineBreak, renderPreview, setEndOfContenteditable}
