import {createSlice, nanoid, PayloadAction} from "@reduxjs/toolkit";
import {defaultApi} from "~st/services/default";

type IEditStatus = "ready" | "edit" | "push"
type IUserEntry = "signIn" | "signInQR" | "signInBind" | "signUp" | "signUpQR" | "resetPasswordRequest" | "resetPasswordSet"

const userSlice = createSlice({
  name: "user",
  initialState: {
    token: window.localStorage.getItem("ylnav_token"),
    expire: window.localStorage.getItem("ylnav_expire"),
    wxId: null as null | number,
    profile: {
      id: null,
      name: null,
      email: null,
      wxId: null,
    } as IProfile,
    userMetas: {
      role: "anonymous",
      adType: "auto",
      adCode: "110101",
      adCity: "北京",
      avatar: "avatar",
      zhihu: "true",
      weibo: "true",
      baidu: "true",
    } as IUserMetas,
    editStatus: "ready" as IEditStatus,
    searchRange: "本站",
    showDrawer: false,
    userEntry: "signIn" as IUserEntry,
    sseId: window.localStorage.getItem("ylnav_sseid"),
    hots: {} as IHotsTransformedData,
  },
  reducers: {
    logout(state) {
      window.localStorage.removeItem("ylnav_token")
      window.localStorage.removeItem("ylnav_expire")
      state.token = null
      state.expire = null
      state.profile = {
        id: null,
        name: null,
        email: null,
        wxId: null,
      }
      state.userMetas = {
        role: "anonymous",
        adType: "auto",
        adCode: "110101",
        adCity: "北京",
      }
    },
    login(state) {
      state.token = window.localStorage.getItem("ylnav_token")
      state.expire = window.localStorage.getItem("ylnav_expire")
    },
    setEditStatus(state, action: PayloadAction<IEditStatus>) {
      state.editStatus = action.payload
    },
    setSearchRange(state, action: PayloadAction<string>) {
      state.searchRange = action.payload
    },
    setShowDrawer(state, action: PayloadAction<boolean>) {
      state.showDrawer = action.payload
    },
    setUserEntry(state, action: PayloadAction<IUserEntry>) {
      state.userEntry = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      defaultApi.endpoints.getPublicCards.matchFulfilled,
      (state) => {
        if (state.sseId === null) {
          const sseId = nanoid()
          window.localStorage.setItem("ylnav_sseid", sseId)
          state.sseId = sseId
        }
      }
    ).addMatcher(
      defaultApi.endpoints.getUserCards.matchFulfilled,
      (state, action) => {
        Object.assign(state.profile, action.payload.profile)
        Object.assign(state.userMetas, action.payload.userMetas)
      }
    ).addMatcher(
      defaultApi.endpoints.getHots.matchFulfilled,
      (state, action) => {
        Object.assign(state.hots, action.payload)
      }
    )
  },
})

export const {logout, login, setEditStatus, setSearchRange, setShowDrawer, setUserEntry} = userSlice.actions
export default userSlice.reducer;