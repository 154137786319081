import {createSlice, PayloadAction} from "@reduxjs/toolkit";

const toastsSlice = createSlice({
  name: "toasts",
  initialState: {
    current: [] as IToast[]
  },
  reducers: {
    addToast(state, action: PayloadAction<IToast>) {
      state.current.push(action.payload)
    },
    removeToast(state, action: PayloadAction<Pick<IToast, "id">>) {
      state.current = state.current.filter(toast => toast.id !== action.payload.id)
    },
  }
})

export const { addToast, removeToast } = toastsSlice.actions;
export default toastsSlice.reducer;

