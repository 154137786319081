import {createSelector, createSlice} from "@reduxjs/toolkit";
import {defaultApi} from "~st/services/default";
import {RootState, useAppSelector} from "~st/store";

const commentsSlice = createSlice({
  name: "comments",
  initialState: {
    current: {} as ICommentsTransformedData,
  },
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      defaultApi.endpoints.getComments.matchFulfilled,
      (state, action) => {
        state.current = action.payload
      }
    )
  }
})

export default commentsSlice.reducer;

const makeFilteredComments = createSelector(
  [
    (state: RootState) => state.comments.current,
    (state: RootState, id: number | null) => id,
    (state: RootState, id: number | null, role: string) => role
  ],
  (comments, id, role) => {
    if (role === "normal") {
      const arr = Object.values(comments.byId).filter(comment => comment.active ||  (!comment.active && comment.userId === id))
      const level1Ids = arr.filter(comment => comment.parent === 0).map(comment => comment.id)
      const byId = arr.reduce((byId: Record<number, IComment>, comment) => {
        byId[comment.id] = comment
        return byId
      }, {})
      return {
        byId,
        level1Ids,
      }
    } else {
      return comments
    }
  }
)

export const FilteredComments = (id: number | null, role: string) => {
  return useAppSelector((state: RootState) => makeFilteredComments(state, id, role))
}