import {useMemo} from "react";
import rfdc from "rfdc";
import {createSelector, createSlice, nanoid, PayloadAction} from "@reduxjs/toolkit";
import {defaultApi} from "~st/services/default";
import {RootState, useAppSelector} from "~st/store";

const implCards = (cards: ICards, id: number) => {
  if (cards.addedIds.includes(id)) {
    cards.addedIds = cards.addedIds.filter(addedId => addedId !== id)
  } else {
    cards.deletedUuids.push(cards.byId[id].uuid)
  }
  if (cards.modifiedIds.includes(id)) {
    cards.modifiedIds = cards.modifiedIds.filter(modifiedId => modifiedId !== id)
  }
}

const cardsSlice = createSlice({
  name: "cards",
  initialState: {
    parentCats: {} as IParentCats,
    cats: {} as ICats,
    cards: {} as ICards,

    publicParentCats: {} as IParentCats,
    publicCats: {} as ICats,
    publicCards: {} as ICards,

    userParentCats: {} as IParentCats,
    userCats: {} as ICats,
    userCards: {} as ICards,

    _ParentCats: {} as IParentCats,
    _Cats: {} as ICats,
    _Cards: {} as ICards,
    activeCat: -1,
    shouldReset: false,
    inViews: {} as Record<number, boolean>,
  },
  reducers: {
    addCat(state, action: PayloadAction<Pick<ICat, "catName" | "parentCatId">>) {
      const {payload: {catName, parentCatId}} = action
      const newCatId = state.cats.addedIds.length > 0 ? state.cats.addedIds[state.cats.addedIds.length-1]-1 : -1
      state.cats.byId[newCatId] = {
        catId: newCatId,
        catUuid: nanoid(),
        catName,
        catIndex: Math.max(...state.parentCats.byId[parentCatId].cats.map(catId => state.cats.byId[catId].catIndex))+1,
        parentCatId,
        cards: []
      }
      state.parentCats.byId[parentCatId].cats.push(newCatId)
      state.cats.addedIds.push(newCatId)

      state.shouldReset = true
    },
    deleteCat(state, action: PayloadAction<Pick<ICat, "catId" | "parentCatId">>) {
      const {payload: {catId, parentCatId}} = action
      state.cats.byId[catId].cards.forEach(id => {
        implCards(state.cards, id)
        delete state.cards.byId[id]
      })

      state.parentCats.byId[parentCatId].cats = state.parentCats.byId[parentCatId].cats.filter(currentCatId => currentCatId !== catId)
      if (state.cats.addedIds.includes(catId)) {
        state.cats.addedIds = state.cats.addedIds.filter(addedCatId => addedCatId !== catId)
      } else {
        state.cats.deletedUuids.push(state.cats.byId[catId].catUuid)
      }
      if (state.cats.modifiedIds.includes(catId)) {
        state.cats.modifiedIds = state.cats.modifiedIds.filter(modifiedCatId => modifiedCatId !== catId)
      }
      delete state.cats.byId[catId]

      if (state.cats.deletedUuids.length > 0 || state.cards.deletedUuids.length > 0) {
        state.shouldReset = true
      }
    },
    updateCat(state, action: PayloadAction<Pick<ICat, "catId" | "catName">>) {
      const {payload: {catId, catName}} = action
      state.cats.byId[catId].catName = catName
      state.cats.modifiedIds.push(catId)

      state.shouldReset = true
    },
    moveCat(state, action: PayloadAction<{catId: number, parentCatId: number, newInx: number}>) {
      const {payload: {catId, parentCatId, newInx}} = action
      state.parentCats.byId[parentCatId].cats = state.parentCats.byId[parentCatId].cats.filter(currentCatId => currentCatId !== catId)
      state.parentCats.byId[parentCatId].cats.splice(newInx, 0, catId)
      state.parentCats.byId[parentCatId].cats.forEach((currentCatId, inx) => state.cats.byId[currentCatId].catIndex = inx)
      state.cats.modifiedIds.push(...state.parentCats.byId[parentCatId].cats)

      state.shouldReset = true
    },

    addCard(state, action: PayloadAction<Omit<ICard, "id" | "uuid" | "index" | "favicon">>) {
      const {payload: {url, name, description, tags, catId, userId}} = action
      const newId = state.cards.addedIds.length > 0 ? state.cards.addedIds[state.cards.addedIds.length-1]-1 : -1
      state.cards.byId[newId] = {
        id: newId,
        uuid: nanoid(),
        url,
        name,
        description,
        tags,
        favicon: "favicon",
        index: Math.max(...state.cats.byId[catId].cards.map(id => state.cards.byId[id].index))+1,
        catId,
        userId
      }
      state.cats.byId[catId].cards.push(newId)
      state.cards.addedIds.push(newId)

      state.shouldReset = true
    },
    deleteCard(state, action: PayloadAction<Pick<ICard, "id" | "catId">>) {
      const {payload: {id, catId}} = action
      state.cats.byId[catId].cards = state.cats.byId[catId].cards.filter(currentId => currentId !== id)
      implCards(state.cards, id)
      delete state.cards.byId[id]

      if (state.cards.deletedUuids.length > 0) {
        state.shouldReset = true
      }
    },
    updateCard(state, action: PayloadAction<Omit<ICard, "uuid" | "index" | "catId" | "userId" | "favicon">>) {
      const {payload: {id, url, name, description, tags}} = action
      state.cards.byId[id] = {
        ...state.cards.byId[id],
        url,
        name,
        description,
        tags,
      }

      state.cards.modifiedIds.push(id)
      state.shouldReset = true
    },
    moveCard(state, action: PayloadAction<{id: number, catId: number, newCatId: number, newInx: number}>) {
      const {payload: {id, catId, newCatId, newInx}} = action
      state.cats.byId[catId].cards = state.cats.byId[catId].cards.filter(currentId => currentId !== id)
      state.cats.byId[newCatId].cards.splice(newInx, 0, id)
      state.cards.byId[id].catId = newCatId
      state.cats.byId[newCatId].cards.forEach((currentId, inx) => state.cards.byId[currentId].index = inx)
      state.cards.modifiedIds.push(...state.cats.byId[newCatId].cards)

      state.shouldReset = true
    },

    setActiveCat(state, action: PayloadAction<number>) {
      state.activeCat = action.payload
    },

    reset(state) {
      state.parentCats = rfdc({ proto: false })(state.publicParentCats);
      state.cats = rfdc({ proto: false })(state.publicCats);
      state.cards = rfdc({ proto: false })(state.publicCards);

      state.parentCats.allIds.unshift(...state.userParentCats.allIds)
      Object.assign(state.parentCats.byId, state.userParentCats.byId)
      Object.assign(state.cats.byId, state.userCats.byId)
      Object.assign(state.cards.byId, state.userCards.byId)

      state.shouldReset = false
    },
    setInView(state, action: PayloadAction<{ id: number, inView: boolean }>) {
      const {payload: {id: catId, inView}} = action
      state.inViews[catId] = inView
      const cats = state.parentCats.byId[state.cats.byId[catId].parentCatId].cats
      cats.some(currentCatId => {
        if (state.inViews[currentCatId]) {
          state.activeCat = currentCatId
          return true
        }
        return false
      })
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      (action) => action.type.endsWith('logout'),
      (state) => {
        state.parentCats = rfdc({ proto: false })(state.publicParentCats);
        state.cats = rfdc({ proto: false })(state.publicCats);
        state.cards = rfdc({ proto: false })(state.publicCards);
      }
    ).addMatcher(
      defaultApi.endpoints.getPublicCards.matchFulfilled,
      (state, action) => {
        state.publicParentCats = action.payload.parentCats;
        state.publicCats = action.payload.cats;
        state.publicCards = action.payload.cards;

        state.parentCats = action.payload.parentCats;
        state.cats = action.payload.cats;
        state.cards = action.payload.cards;
      }
    ).addMatcher(
      defaultApi.endpoints.getUserCards.matchFulfilled,
      (state, action) => {
        state.userParentCats = action.payload.parentCats;
        state.userCats = action.payload.cats;
        state.userCards = action.payload.cards;

        if (state.shouldReset) {
          state.parentCats = rfdc({ proto: false })(state.publicParentCats);
          state.cats = rfdc({ proto: false })(state.publicCats);
          state.cards = rfdc({ proto: false })(state.publicCards);
          state.shouldReset = false;
        }

        state.parentCats.allIds.unshift(...action.payload.parentCats.allIds)
        Object.assign(state.parentCats.byId, action.payload.parentCats.byId)
        Object.assign(state.cats.byId, action.payload.cats.byId)
        Object.assign(state.cards.byId, action.payload.cards.byId)
      }
    ).addMatcher(
      defaultApi.endpoints.postUserMetas.matchFulfilled,
      (state) => {
        state.shouldReset = true
      }
    ).addMatcher(
      defaultApi.endpoints.postImport.matchFulfilled,
      (state) => {
        state.shouldReset = true
      }
    )
  },
});


export const { addCat, deleteCat, updateCat, moveCat, addCard, deleteCard, updateCard, moveCard, setActiveCat, reset, setInView } = cardsSlice.actions;
export default cardsSlice.reducer;


const makeIsCustomParentCatByCatId = () => {
  return createSelector(
    [
      (state: RootState) => state.cards.parentCats.byId,
      (state: RootState) => state.cards.cats.byId,
      (state: RootState, catId: number) => catId
    ],
    (parentCats, cats, catId) => parentCats[cats[catId].parentCatId].parentCatName === "自定义"
  )
}

export const IsCustomParentCatByCatId = (catId: number) => {
  const instance = useMemo(makeIsCustomParentCatByCatId, [])
  return useAppSelector((state: RootState) => instance(state, catId))
}

const makeIsCustomParentCatByParentCatId = () => {
  return createSelector(
    [
      (state: RootState) => state.cards.parentCats.byId,
      (state: RootState, parentCatId: number) => parentCatId
    ],
    (parentCats, parentCatId) => parentCats[parentCatId].parentCatName === "自定义"
  )
}

export const IsCustomParentCatByParentCatId = (parentCatId: number) => {
  const instance = useMemo(makeIsCustomParentCatByParentCatId, [])
  return useAppSelector((state: RootState) => instance(state, parentCatId))
}

const makeCatIdNameObj = createSelector(
  [
    (state: RootState) => state.cards.parentCats.byId,
    (state: RootState) => state.cards.parentCats.allIds,
    (state: RootState) => state.cards.cats.byId,
  ],
  (parentCats, parentCatIds, cats) => {
    const out = {} as Record<number, string>
    const customParentCat = parentCats[parentCatIds[0]]
    if (customParentCat.parentCatName === "自定义") {
      customParentCat.cats.forEach(catId => {
        out[catId] = cats[catId].catName
      })
    }
    return out
  }
)

export const CatIdNameObj = () => {
  return useAppSelector((state: RootState) => makeCatIdNameObj(state))
}
