import {lazy, Suspense} from "react";
import {Provider} from "react-redux";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {createRoot} from 'react-dom/client';
import store from "~st/store";
import {IconSpinner} from "~utils/icon";

import 'index.css';

const App = lazy(() => import('views/app'))
const UserEntry = lazy(() => import("views/userEntry"))

const root = createRoot(
  document.getElementById("root") as Element
);
root.render (
  <Provider store={store}>
    <BrowserRouter>
      <Suspense fallback={
        <div className="flex justify-center items-center h-screen">
          <IconSpinner size={8} bgColor={"text-gray-200"} fgColor={"fill-pink-500"} isInline={false} />
        </div>
      }>
        <Routes>
          <Route path="/" element={<App />} />
          <Route path="/index" element={<App />} />
          <Route path="/cat/:parent_cat_id" element={<App />} />
          <Route path="/search" element={<App />} />
          <Route path="/user/setting" element={<App />} />
          <Route path="/user/terms" element={<App />} />

          <Route path="/user/entry" element={<UserEntry />} />
          <Route path="*" element={<App />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  </Provider>
)



