import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {RootState} from "~st/store";
import {snakeCaseToCamel} from "~utils/helper";

const transformResponse = (response: ICardsRawData): ICardsTransformedData => {
  const parentCatsById = response["data"].reduce((byId: Record<number, IParentCat>, parentCat) => {
    byId[parentCat.parentCatId] = {
      ...parentCat,
      cats: parentCat.cats.map(cat => cat.catId)
    }
    return byId
  }, {})

  const catById = response["data"].flatMap(parentCat => parentCat.cats).reduce((byId: Record<number, ICat>, cat) => {
    byId[cat.catId] = {
      ...cat,
      cards: cat.cards.map(card => card.id)
    }
    return byId
  }, {})

  const cardById = response["data"].flatMap(parentCat => parentCat.cats).flatMap(cat => cat.cards).reduce((byId: Record<number, ICard>, card) => {
    byId[card.id] = card
    return byId
  }, {})

  const userMetas = response["userMetas"].reduce((result: Record<string, string>, el) => {
    result[snakeCaseToCamel(el.key)] = el.value
    return result
  }, {})

  return {
    parentCats: {
      byId: parentCatsById,
      allIds: Object.keys(parentCatsById).map(parentCatId => +parentCatId)
    },
    cats: {
      byId: catById,
      addedIds: [],
      deletedUuids: [],
      modifiedIds: []
    },
    cards: {
      byId: cardById,
      addedIds: [],
      deletedUuids: [],
      modifiedIds: [],
    },
    profile: response["profile"],
    userMetas,
  }
}

const transformHotsResponse = (response: IHotsRawData): IHotsTransformedData => {
  const byId = Object.entries(response["data"]).reduce((byId: Record<string, IHotWithSite>, hots) => {
    Object.assign(byId, hots[1].reduce((temp: Record<string, IHotWithSite>, hot) => {
      temp[hot.uuid] = {
        ...hot,
        site: hots[0],
      } as IHotWithSite
      return temp
    }, {}))
    return byId
  }, {})

  const zhihu = []
  const weibo = []
  const baidu = []
  for (const [site, hots] of Object.entries(response["data"])) {
    if (site === "zhihu") {
      zhihu.push(...hots.map(hot => hot.uuid))
    }
    if (site === "weibo") {
      weibo.push(...hots.map(hot => hot.uuid))
    }
    if (site === "baidu") {
      baidu.push(...hots.map(hot => hot.uuid))
    }
  }
  return {
    byId,
    zhihu,
    weibo,
    baidu,
  }
}

const transformCommentsResponse = (response: ICommentsRawData): ICommentsTransformedData => {
  const byId = response["data"].reduce((byId: Record<number, IComment>, comment) => {
    byId[comment.id] = {
      id: comment.id,
      content: comment.content,
      createdAt: comment.createdAt,
      userId: comment.userId,
      name: comment.name,
      role: comment.role,
      avatar: comment.avatar,
      active: comment.active,
      parent: comment.parent,
      children: []
    }
    return byId
  }, {})
  const level1Ids = [] as number[]
  response["data"].forEach(comment => {
    if (comment.parent !== 0 && !byId[comment.parent].children.includes(comment.id)) {
      byId[comment.parent].children.push(comment.id)
    }
    if (comment.parent === 0 && !level1Ids.includes(comment.id)) {
      level1Ids.push(comment.id)
    }
  })
  return {
    byId,
    level1Ids,
  }
}


export const defaultApi = createApi({
  reducerPath: "defaultApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "/api/",
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).user.token
      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }
      return headers
    },
  }),
  tagTypes: ["publicCards", "userCards", "comments"],
  endpoints: (builder) => ({
    getPublicCards: builder.query<ICardsTransformedData, void>({
      query: () => "cards",
      transformResponse,
      providesTags: ["publicCards"],
    }),
    getUserCards: builder.query<ICardsTransformedData, void> ({
      query: () => "user/cards",
      transformResponse,
      providesTags: ["userCards"],
    }),
    postUserCards: builder.mutation< { message: string, invalidatesTags: string }, {
        addedCats: IPostCat[],
        deletedCats: string[],
        modifiedCats: IPostCat[],
        addedCards: IPostCard[],
        deletedCards: string[],
        modifiedCards: IPostCard[],
      }> ({
      query: (postParams) => ({
        url: "user/cards",
        method: "POST",
        body: postParams
      }),
      invalidatesTags: (result) => result?.invalidatesTags === "both" ? ["publicCards", "userCards"] : ["userCards"]
    }),

    postImport: builder.mutation<{message: string}, FormData> ({
      query: (postParams) => ({
        url: "user/import",
        method: "POST",
        body: postParams
      }),
      invalidatesTags: ["userCards"]
    }),
    postExport: builder.mutation<{message: string}, void> ({
      query: () => ({
        url: "user/export",
        method: "POST",
      }),
    }),

    postUserPreRegister: builder.mutation<{message: string}, {name: string, email: string, password: string, sseId: string}> ({
      query: ({name, email, password, sseId}) => ({
        url: "user/pre-register",
        method: "POST",
        body: {name, email, password, sseId}
      })
    }),
    postUserLogin: builder.mutation<{expire: string, token: string, wx_id: string}, {email: string, password: string, remember: boolean, sseId: string}> ({
      query: ({email, password, remember, sseId}) => ({
        url: "user/login",
        method: "POST",
        body: {email, password, remember, sseId}
      }),
      invalidatesTags: ["userCards"]
    }),
    postUserResetPasswordRequest: builder.mutation<{message: string}, {email: string}> ({
      query: ({email}) => ({
        url: "user/reset-password",
        method: "POST",
        body: {email}
      })
    }),
    postUserResetPasswordSet: builder.mutation<{message: string}, {email: string, password: string, token: string}> ({
      query: ({email, password, token}) => ({
        url: "user/reset-password",
        method: "POST",
        body: {email, password, token}
      })
    }),
    postUserChangePassword: builder.mutation<{message: string}, {oldPassword: string, password: string}> ({
      query: ({oldPassword, password}) => ({
        url: "user/change-password",
        method: "POST",
        body: {oldPassword, password}
      })
    }),
    postUserMetas: builder.mutation<{message: string}, {userMetas: {userId: number, key: string, value: string}[]}> ({
      query: (postParams) => ({
        url: "user/metas",
        method: "POST",
        body: postParams
      }),
      invalidatesTags: ["userCards"]
    }),

    postWeatherCasts: builder.mutation<{message: string}, { adType: string, adCode: string, sseId: string }> ({
      query: ({adType, adCode, sseId}) => ({
        url: "weather/casts",
        method: "POST",
        body: {adType, adCode, sseId}
      }),
    }),
    postWeatherDistricts: builder.mutation<{message: string}, { keywords: string, sseId: string }> ({
      query: ({keywords, sseId}) => ({
        url: "weather/districts",
        method: "POST",
        body: {keywords, sseId}
      }),
    }),

    postWxQRCode: builder.mutation<{message: string}, { sseId: string, entry: "in" | "up" | "bd" }> ({
      query: ({sseId, entry}) => ({
        url: "vu/qrcode",
        method: "POST",
        body: {sseId, entry}
      }),
    }),
    getHots: builder.query<IHotsTransformedData, void>({
      query: () => "hots",
      transformResponse: transformHotsResponse,
    }),
    postWxUnbind: builder.mutation<{message: string}, void> ({
      query: () => ({
        url: "user/unbind",
        method: "POST",
      }),
    }),
    getComments: builder.query<ICommentsTransformedData, void>({
      query: () => "comments",
      transformResponse: transformCommentsResponse,
      providesTags: (result) => result ? [...Object.keys(result.byId).map((id) => ({type: "comments" as const, id})), "comments"] : ["comments"]
    }),
    postInsertComment: builder.mutation<{data: number, message: string}, { content: string, parent: number }> ({
      query: ({content, parent}) => ({
        url: "user/comment",
        method: "POST",
        body: {content, parent},
      }),
      invalidatesTags: ["comments"]
    }),
    patchUpdateComment: builder.mutation<{message: string}, {id: number, content: string}> ({
      query: ({id, content}) => ({
        url: "user/comment",
        method: "PATCH",
        body: {id, content},
      }),
      invalidatesTags: (result, error, arg) => [{type: "comments", id: arg.id}]
    }),
    deleteComment: builder.mutation<{message: string}, { id: number }> ({
      query: ({id}) => ({
        url: "user/comment",
        method: "DELETE",
        body: {id}
      }),
      invalidatesTags: (result, error, arg) => [{type: "comments", id: arg.id}]
    }),
    fetchCardFavicon: builder.mutation<{message: string}, { url: string }> ({
      query: ({url}) => ({
        url: "user/favicon",
        method: "PATCH",
        body: {url}
      }),
      invalidatesTags: ["publicCards"],
    }),
    uploadImageAsFavicon: builder.mutation<{message: string}, FormData> ({
      query: (postParams) => ({
        url: "user/favicon",
        method: "POST",
        body: postParams
      }),
      invalidatesTags: ["publicCards"],
    }),
  }),
})

export const { useGetPublicCardsQuery, usePostUserPreRegisterMutation, usePostUserLoginMutation, usePostUserResetPasswordRequestMutation, usePostUserResetPasswordSetMutation, usePostUserChangePasswordMutation, useGetUserCardsQuery, usePostUserCardsMutation, usePostUserMetasMutation, usePostImportMutation, usePostExportMutation, usePostWeatherCastsMutation, usePostWeatherDistrictsMutation, usePostWxQRCodeMutation, useGetHotsQuery, usePostWxUnbindMutation, useGetCommentsQuery, usePostInsertCommentMutation, usePatchUpdateCommentMutation, useDeleteCommentMutation, useFetchCardFaviconMutation, useUploadImageAsFaviconMutation } = defaultApi






