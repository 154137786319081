import {TypedUseSelectorHook, useDispatch, useSelector} from "react-redux";
import {configureStore} from "@reduxjs/toolkit";
import {defaultApi} from "~st/services/default";
import cardsReducer from "~st/slices/cardsSlice";
import commentsSlice from "~st/slices/commentsSlice";
import toastsSlice from "~st/slices/toastsSlice";
import userSlice from "~st/slices/userSlice";

const store = configureStore({
  reducer: {
    [defaultApi.reducerPath]: defaultApi.reducer,
    cards: cardsReducer,
    user: userSlice,
    toasts: toastsSlice,
    comments: commentsSlice,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(defaultApi.middleware),
  devTools: process.env.NODE_ENV !== "production",
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector